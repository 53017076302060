import parse from 'html-react-parser';
import styles from "./GroupInvite.module.css";
import React, { useState, useEffect, useRef, } from 'react';  
import Helper from './Helper';
import { CloseBlack, BackArrow, } from '../icons';
import Faq from "react-faq-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HiveSuccess from "../pages/HiveSuccess";
import ReactPlayer from 'react-player';


const GroupInvite = (props) => {
  const myForm = useRef(null);
  const { height, width } = Helper.getWindowDimensions();
  const [currentStep, setCurrentStep] = useState(1);
  const [formState, setFormState] = useState({
    groupId: props.group['_id'],
    phone: '',
    name: '',
    email: '',
    ...props.group.activities.reduce((acc, activity) => {
      acc[activity.name] = [];

      activity.data.forEach((act) => {
        if (act.disableDeselect) {
          acc[activity.name].push(act.title);  
        }
      });

      if (acc[activity.name].length === 0) acc[activity.name] = [activity.data[0].title];
      return acc;
    }, {})
  });
  const customizedActivities = props.group.activities.filter(a => a.data.filter(b => b.disableDeselect).length !== a.data.length);
  const defaultActivities = props.group.activities.filter(a => a.data.filter(b => b.disableDeselect).length === a.data.length);
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [scrollbarLeft, setScrollbarLeft] = useState({});
  const faqStyles = {
    titleTextColor: "#000",
    rowTitleColor: "#000",
  };
  const [readMore, setReadMore] = useState({});

  const genericFaqs = [{
    title: 'What happens after I pay the token? ',
    content: 'Our team will contact you and add you to a group with like-minded travelers and the experience creator to co-plan your trip.'
  }, {
    title: 'Is the token refundable? ',
    content: 'Yes, you’ll receive a full refund if you decide not to confirm your trip.'
  },
  {
    title: 'Who are the experience creators? ',
    content: 'They are expert solo travelers who craft unique, offbeat experiences and guide you along the way.'
  },
  {
    title: 'Can I customize my itinerary? ',
    content: 'Yes! You’ll co-plan with your group, but final decisions rest with the curator for feasibility and safety.'
  },
  {
    title: 'What if I need to cancel after confirming my trip? ',
    content: 'If you cancel at least 2 weeks before the trip, you’ll receive a 60% refund. For cancellations at least 1 week before, you’ll get a 40% refund. After that, refunds are at the curator’s discretion, but our support team will be there to assist you.'
  },
  {
    title: 'Can I extend my trip or arrive earlier? ',
    content: 'Yes! The XPhive team will help with planning, including stays, food, activities, and experiences.'
  },
  {
    title: 'What if I don’t like my group after joining? ',
    content: 'You can cancel anytime before confirmation and get a full refund on your token.'
  }];

  const closeTour = (e) => {
    e.stopPropagation();
    setIsTourOpen(false);
  };

  const onClickChoice = (e, que, type, val, selectionCap=0, disableDeselect=false) => {
    if (selectionCap > 0 && isTourOpen) setIsTourOpen(false);

    formState[que] = formState[que] || [];

    if (disableDeselect && formState[que].includes(val)) {
      toast.warning("This item cannot be deselected", Helper.ToastOptions);
      return;
    }

    if (type === 'choice-single') {
      if (formState[que][0] === val) {
        formState[que][0] = '';
        Helper.trackAnalytics(`GroupInvite_${props.group.title.split(' ').join('_')}`, 'Click_Remove_choice', val);
      } else {
        formState[que][0] = val;
        Helper.trackAnalytics(`GroupInvite_${props.group.title.split(' ').join('_')}`, 'Click_Add_choice', val);
      }
    } else {
      if (formState[que].includes(val)) {
        formState[que] = formState[que].filter(av => av !== val);
        Helper.trackAnalytics(`GroupInvite_${props.group.title.split(' ').join('_')}`, 'Click_Remove_choice', val);
      } else {
        if (selectionCap > 0) {
          if (formState[que].length < selectionCap) {
            formState[que].push(val);
            Helper.trackAnalytics(`GroupInvite_${props.group.title.split(' ').join('_')}`, 'Click_Add_choice', val);
          }
        } else {
          formState[que].push(val);
          Helper.trackAnalytics(`GroupInvite_${props.group.title.split(' ').join('_')}`, 'Click_Add_choice', val);
        }
      }
    }

    setFormState({...formState});
  };

  const onChangeInput = (e, type) => {
      e.preventDefault();

      if (type === 'phone') {
        formErrorState['phone'] = false;
        setFormErrorState({...formErrorState});
        if (e.target.value.length < 11) {
          formState['phone'] = e.target.value;
          setFormState({...formState});
        }
      } else if (type === 'name') {
        formErrorState.name = false;
        setFormErrorState({...formErrorState});
        const result = e.target.value.replace(/[^a-z ]/gi, '');
        formState.name = result;
        setFormState({...formState});
      } else if (type === 'email') {
        formErrorState.email = false;
        setFormErrorState({...formErrorState});
        formState.email = e.target.value;
        setFormState({...formState});
      } else {
        formErrorState[type] = false;
        setFormErrorState({...formErrorState});
        formState[type] = e.target.value;
        setFormState({...formState});
      }
    };

  const setLeads = (leadId) => {
    props.setLeadSubmitted(true);
    Helper.storage.setItem(`GroupInvite_${props.group['_id']}`, leadId);
  };

  const removeEmptyArrays = (obj) => {
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key]) && (obj[key].length === 0 || (obj[key].length === 1 && obj[key][0] === ''))) {
            delete obj[key];
        }
    });
    return obj;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!formState.name || formState.phone.length != 10 || !formState.email) {
      toast.error('Please fill the details', Helper.ToastOptions);
      myForm.current.scrollIntoView();
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email) {
      Helper.trackAnalytics(`GroupInvite_${props.group.title.split(' ').join('_')}`, 'Click_Join_Hive', formState.phone);
      setSubmittingForm(true);
      props.setShowLoader(true);
      const details = {...formState};
      delete details.name;
      delete details.email;
      delete details.phone;
      removeEmptyArrays(details);
      details.price = getPrice();
      const tokenAmount = (props.group.paymentAmount || 0) > 0 ? props.group.paymentAmount : 0;
      details.tokenAmount = tokenAmount;

      await fetch(`${global.config.SERVER_URL}/api/v1/leads/hive`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          details,
          paymentAmount: tokenAmount,
          type: 'groupInvite',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          props.setShowLoader(false);
          if (data.status == 'success') {
            setLeads(data.leadId);
            if (data.pgRes.amount > 0) {
              const razorPayOptions = getRazorPayOptions(data);
              const rzp = new Razorpay(razorPayOptions);
              rzp.open();
            }
          }
        })
        .catch((err) => {
          setSubmittingForm(false);
          props.setShowLoader(false);
          console.log(err.message);
        });
    }
  };

  const getRazorPayOptions = (data) => {
    return {
      "key": global.config.RAZOR_PAY_KEY,
      "amount": data.pgRes.amount,
      "currency": "INR",
      "name": "XpHive",
      "description": props.group.title,
      "image": "https://xphive-prod.s3.ap-south-1.amazonaws.com/1d018d5451db3929e64cad2253b3b805.jpeg",
      "order_id": data.pgRes.id,
      "callback_url": `${global.config.SERVER_URL}/api/v1/payments/success`,
      "prefill": {
          "name": formState.name,
          "email": "",
          "contact": formState.phone
      },
      "notes": {
          "leadId": data.leadId
      },
      "theme": {
          "color": "#3399cc"
      }
    };
  };

  const handleBytesScroll = (index, items, i) => {
    const totalScrollPossible = ((width * 0.5) - 15) * (items - 2);
    let left = (document.getElementById('activityContainer' + index + i).scrollLeft / totalScrollPossible) * 100;
    left = left >= 100 ? 100 : left;
    const substract = left >= 80 ? ' - ' + ((left - 80) * 1.75) + 'px' : '';
    scrollbarLeft[index.toString() + i.toString()] = 'calc(' + left + '%' + substract  + ')';
    setScrollbarLeft({...scrollbarLeft});
  };

  const toggleReadMore = (i) => {
    let readMoreCopy = {...readMore};
    readMoreCopy[i] = readMoreCopy[i] ? false : true;
    setReadMore(readMoreCopy);
  };

  const getActivities = (index, activities, defaultActivities=false) => {
    return activities.map((activity, i) => {
      return(<div className={styles.activities} style={{marginTop: defaultActivities ? '20px': 0}}>
        <div className={styles.activityName}>
          <h4 style={{width: defaultActivities ? 'calc(100% - 10px)' : ''}}>{activity.name}</h4>
          {!defaultActivities && <span>Maximum Selections: {activity.selectionCap < 10 ? '0' + activity.selectionCap : activity.selectionCap}</span>}
        </div>

        <div className={styles.activitiesContainerParent} id={'activityContainer' + index + i} onScroll={() => handleBytesScroll(index, activity.data.length, i)}>
          {activity.data.map((activityData, ind) => {
            return(<div style={{width: (width * 0.5) - 15}} className={styles.activityQue} key={ind} onClick={e => onClickChoice(e, activity.name, activity.selectionCap > 1 ? 'choice-multi' : 'choice-single', activityData.title, activity.selectionCap, activityData.disableDeselect)}>
              <div className={styles.activityImgWrap}>
                <div style={{width: (width * 0.5) - 15, height: (width * 0.5) - 15, backgroundImage: `url(${activityData.image})`}} className={((formState[activity.name] || []).includes(activityData.title) ?  (!defaultActivities ? styles.activityImgSelected : styles.defaultActivityImgSelected) : styles.activityImg)}></div>
                {!defaultActivities && ind === 1 && i === 0 && isTourOpen && <div className={styles.tourWrap}>
                  <i onClick={(e) => closeTour(e)} className={styles.closeIcon}><CloseBlack /></i>
                  <label>Click on the image to select / deselect</label>
                  <div className={styles.leftArrow}></div>
                </div>}

                {(formState[activity.name] || []).includes(activityData.title) && !defaultActivities && 
                <img className={styles.checkIcon} src="/check-icon-yellow2.png" />}

                {activityData.disableDeselect && <label className={styles.creatorTag}>Creator’s Choice</label>}
              </div>
              <label className={styles.activityTitle}>{activityData.title}</label>
              <label className={styles.activitySubTitle}>{activityData.subtitle}</label>
              {activityData.subSubtitle && <label className={styles.activitySubSubTitle}>{activityData.subSubtitle}</label>}
              <p className={styles.activityDesc}>
                {readMore[activityData.title + ind] ? parse(activityData.description) : parse(activityData.description.slice(0, 200)) }
                {activityData.description.length > 200 && <span className={styles.readMore} onClick={e => toggleReadMore(activityData.title + ind)}>
                    {readMore[activityData.title + ind] ? ' Read Less' : '... Read More' }
                </span>}
              </p>
            </div>);
          })}
        </div>

        {activity.data.length > 2 && <div className={styles.scrollWrap}>
          <span className={styles.scrollLine}></span>
          <span className={styles.scrollBar} style={{'left': scrollbarLeft[index.toString() + i.toString()] || 0}}></span>
        </div>}
      </div>);
    });
  };

  const getPrice = () => {
    let price = 0;
    props.group.activities.forEach((activity) => {
      if (activity.name in formState) {
        activity.data.forEach((activityData) => {
          if (formState[activity.name].includes(activityData.title)) {
            price += activityData.price;
          }
        });
      }
    });
    return price;
  };

  const getQuestionnaires = () => {
    return props.group.questionnaires.map((inputQue, i) => {
      return(
        (inputQue.type === 'choice-single' || inputQue.type === 'choice-multi') ? 
          <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            {inputQue.choices.map((choice, j) => {
                return(<span key={j} onClick={e => onClickChoice(e, inputQue.question, inputQue.type, choice)} className={(formState[inputQue.question] || []).includes(choice) ? styles.choiceSelected : styles.choice}>{choice}</span>)
            })}
          </div>
        : (inputQue.type === 'textarea' ?
          <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            <textarea className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} placeholder={inputQue.placeholder} value={formState[inputQue.question]} rows="3" onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
          : <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            <input className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} type={inputQue.type} placeholder={inputQue.placeholder} value={formState[inputQue.question]} onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
        )
      )
    });
  };
  
  return (
      <div className={styles.groupInviteContainer}>
        {(!props.leadSubmitted && currentStep === 1) &&
          <div>
            {defaultActivities.length > 0 && <h1 className={styles.heading}>Curated Experiences</h1>}
            {defaultActivities.length > 0 && <p className={styles.info}><img src="/information-circle-stroke-rounded.svg" />Fixed experiences chosen by the creator for you</p>}
            {defaultActivities.length > 0 && getActivities(1, defaultActivities, true)}
            {customizedActivities.length > 0 && <h1 className={styles.heading}>Customize your Experience</h1>}
            {customizedActivities.length > 0 && <p className={styles.info}><img src="/information-circle-stroke-rounded.svg" />Select the experiences that best match your preferences</p>}
            {customizedActivities.length > 0 && props.group.activitiesDesc && <p className={styles.activitiesDesc}>{props.group.activitiesDesc}</p>}

            {customizedActivities.length > 0 && getActivities(2, customizedActivities)}

            {props.group && props.group.faqs && <div className="groupFaqContainer">
              <Faq
                data={{title: "FAQs", rows: props.group.faqs.data}}
                styles={faqStyles}
                config={{}}
              />
            </div>}

            <div className={styles.priceAndJoinBtn}>
              <div className={styles.priceInfo}>
                <p className={styles.price}>Estimated Trip Cost <span>{getPrice() > 0 ? '₹' + getPrice().toLocaleString() : '-'}</span></p>
              </div>

              <button onClick={(e) => setCurrentStep(2)} style={{padding: props.group.paymentMessage ? '6px': '8px 6px'}} className={styles.joinBtn} type="submit">Continue -></button>

              <label className={styles.info}>
                {props.group.paymentMessage ? props.group.paymentMessage : ''}
              </label>
            </div>
          </div>
        }

        {(!props.leadSubmitted && currentStep === 2) &&
          <div className={styles.secondStepContainer}>
            <i onClick={(e) => setCurrentStep(1)} className={styles.backArrow}><BackArrow /></i>

            <div className={styles.innerSecondStep}>
              <h1 className={styles.secondStepHeading}>Travel Solo, We’ll Handle the Rest!</h1>
              <div className={styles.groupInfo}>
                <h3>{props.group.title}</h3>
                <div className={styles.imgWrap}>{props.group.image.includes(".mp4") ? <ReactPlayer className={styles.videoContainer} url={props.group.image} playsinline muted={true} loop={true} playing={true} width={(width - 60) * 0.35} height={((width - 60) * 0.35) * 1.25} config={{file: {attributes: {crossOrigin: 'true',},},}} /> : <img style={{width: (width - 60) * 0.35, height: ((width - 60) * 0.35) * 1.25}} src={props.group.image} />}</div>
                <p>Hello <b>Explorer</b>!<br/>Welcome to your next adventure! Get ready to connect with your creator for this experience, and a group of like-minded explorers.<br/>Your journey starts now!<br/><br/>– Team XPhive</p>
              </div>

              <div className={styles.hiwWrap}>
                <h2>How It Works!</h2>
                <img src="/group-hiw-v2.png" />
              </div>
              <h2 className={styles.heading} ref={myForm}>Take the first step</h2>
              <p className={styles.info}><img src="/information-circle-stroke-rounded.svg" />Groups balanced for preferences and quality</p>

              <form onSubmit={handleSubmit}>
                <div>
                  <label className={styles.label}>Your name... *</label>
                  <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
                </div>

                <div>
                  <label className={styles.label}>WhatsApp number... *</label>
                  <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
                </div>

                <div>
                  <label className={styles.label}>Email ID... *</label>
                  <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
                </div>

                {getQuestionnaires()}

                <div className={styles.priceAndJoinBtn}>
                  <div className={styles.priceInfo}>
                    <p className={styles.price}>Estimated Trip Cost <span>{getPrice() > 0 ? '₹' + getPrice().toLocaleString() : '-'}</span></p>
                  </div>

                  <button style={{padding: props.group.paymentMessage ? '6px': '8px 6px'}} className={styles.joinBtn} type="submit">Book Your Spot</button>

                  <label className={styles.info}>
                    {props.group.paymentMessage ? props.group.paymentMessage : ''}
                  </label>
                </div>
              </form>

              <div className={styles.whatsappWrap}>
                Still unsure or need more details?
                <a className={styles.whatsappBtn} target="_blank" href={`https://wa.me/+919972136020/?text=Hi`}><img src="/wa-v1.png" />Reach out to us!</a>
              </div>

              {<div className="genericFaqContainer">
                <Faq
                  data={{title: "FAQs", rows: genericFaqs}}
                  styles={faqStyles}
                  config={{}}
                />
              </div>}

              <div className={styles.ourPromise}>
                <h4>Our Promise to You!</h4>
                <img src="/promise1.png" />
                <img src="/promise2.png" />
                <img src="/promise3.png" />
                <img src="/promise4.png" />
              </div>

              <div className={styles.team}>
                <h3>The Team Behind XPhive</h3>
                <div className={styles.teamInner}>
                  <div>
                    <div className={styles.teamMember}>
                      <img src="/hemant-img.jpeg" />
                      <label>Hemant</label>
                      <p>A passionate biker, journeyed across India from Bangalore to Goa,  Himachal, and Leh during lockdown. A true explorer and LOTR fan, he believes  the world isn’t in books and maps—it’s out there waiting to be experienced.</p>
                    </div>
                    <div className={styles.teamMember}>
                      <img src="/piyush-img1.jpeg" />
                      <label>Piyush</label>
                      <p>A corporate dropout, found his true calling on a transformative bike journey across India. An avid traveler drawn to unconventional adventures, he founded Free Spirits Tribe—a café and hostel in Naggar—and left corporate life behind to embrace the Himalayas permanently.</p>
                    </div>
                    <div className={styles.teamMember}>
                      <img src="/ashad-img.jpg" />
                      <label>Ashad</label>
                      <p>Living the 2nd innings of his life being a“Chai wala” after hustling with a couple of Startup founders (read High-functioning Sociopaths) for 10 years, creating SAAS products and Selling them. Now, finding passion and cause in the high mountains of Himalayas.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{height: '150px', width: '100%'}}></div>

              <ToastContainer />
            </div>
          </div>
        }

        {props.leadSubmitted && 
          <div>
            <h3 className={styles.success}>We've got your experience preferences and we're on it! Our team will match you with groups that fit your vibe. We'll be in touch soon with some great options.
              <br/>Happy Exploring! 🌍✈️
            </h3>

            {<HiveSuccess leadId={Helper.storage.getItem(`GroupInvite_${props.group['_id']}`)} />}
          </div>
        }
    </div>
  )
}

export default GroupInvite;
