import styles from "./GetACallBack.module.css";
import React, { useState, useEffect } from 'react';
import { Helper, } from "../components";


const GetACallBack = () => {
  const [formState, setFormState] = useState({
    phone: '',
    name: '',
    location: '',
    message: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    location: false,
    message: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);
  const [leadSubmitted, setLeadSubmitted] = useState(false);

  const onChangeInput = (e, type) => {
      e.preventDefault();

      if (type === 'phone') {
        formErrorState['phone'] = false;
        setFormErrorState({...formErrorState});
        if (e.target.value.length < 11) {
          formState['phone'] = e.target.value;
          setFormState({...formState});
        }
      } else if (type === 'name') {
        formErrorState.name = false;
        setFormErrorState({...formErrorState});
        const result = e.target.value.replace(/[^a-z ]/gi, '');
        formState.name = result;
        setFormState({...formState});
      } else if (type === 'location') {
        formState.location = e.target.value;
        setFormState({...formState});
      } else {
        formState.message = e.target.value;
        setFormState({...formState});
      }
    };

  const setLeads = () => {
    setLeadSubmitted(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!submittingForm && formState.name && formState.phone && !formErrorState.name && !formErrorState.phone) {
      Helper.trackAnalytics(`GetACallBack`, 'Click_Submit_details', formState.phone);
      setSubmittingForm(true);

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          location: formState.location,
          phone: formState.phone,
          message: formState.message,
          type: 'GetACallBack',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false);
          console.log(err.message);
        });
    }
  };
  
  return (
    <div className={styles.getACallBackContainer}>
      <h1 className={styles.pageHeading}>Get a Call Back</h1>
      <span className={styles.info}><img src="/information-circle-stroke-rounded.svg" />Your info stays safe and is used only to contact you with your consent.</span>

      {!leadSubmitted &&
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <label className={styles.label}>Please tell us your name *</label>
              <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
            </div>

            <div>
              <label className={styles.label}>WhatsApp number... *</label>
              <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
            </div>

            <div>
              <label className={styles.label}>What is your current location?</label>
              <input className={formErrorState.location ? styles.invalid : styles.valid} type="text" placeholder="Location" value={formState.location} onChange={e => onChangeInput(e, 'location')} />
            </div>

            <div>
              <label className={styles.label}>Tell us about yourself and how we can assist you!</label>
              <textarea className={formErrorState.message ? styles.invalid : styles.valid} placeholder="Type your query here..." value={formState.message} rows="4" onChange={e => onChangeInput(e, 'message')} />
            </div>
            <button type="submit">Request a Call</button>
          </form>
        </div>
      }

      {leadSubmitted && 
        <div>
          <h3 className={styles.success}>Thank you for contacting us!! We will revert back soon.</h3>
        </div>
      }
    </div>
  )
}

export default GetACallBack;
