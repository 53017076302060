import styles from "./Filters.module.css";
import React, { useState, } from "react";

const Filters = (props) => {
  const [currentTab, setCurrentTab] = useState('vibes');
  let [selectedCategories, setSelectedCategories] = useState([...props.selectedCategories]);
  let [selectedDurations, setSelectedDurations] = useState([...props.selectedDurations]);

  React.useEffect(() => {
    setSelectedCategories([...props.selectedCategories])
  }, [props.selectedCategories]);

  React.useEffect(() => {
    setSelectedDurations([...props.selectedDurations])
  }, [props.selectedDurations]);

  const onCategoryClick = (e, category) => {
    if (selectedCategories.includes(category['_id'])) {
      selectedCategories = selectedCategories.filter(sc => sc !== category['_id']);
    } else {
      selectedCategories.push(category['_id']);
    }

    setSelectedCategories([...selectedCategories]);
  };

  const onDurationClick = (e, duration) => {
    if (selectedDurations.includes(duration)) {
      selectedDurations = selectedDurations.filter(sc => sc !== duration);
    } else {
      selectedDurations.push(duration);
    }

    setSelectedDurations([...selectedDurations]);
  };

  const selectFilter = (e, filter) => {
    setCurrentTab(filter);
  };

  const clearFilters = (e) => {
    setSelectedCategories([]);
    setSelectedDurations([]);
  };

  const applyFilters = (e) => {
    props.applyFilters(selectedCategories, selectedDurations);
    props.toggleFilter(e, true);
  };

  const checkboxIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="white" strokeWidth="2.5"/>
      </svg>
    )
  };

  const checkboxSelectedIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="white" strokeWidth="2.5"/>
        <path d="M8 12.5L10.5 15L16 9" stroke="#FFEA00" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  };

  return(
    <div style={{display: 'inline-block'}}>
      <div className={props.open ? styles.filterSectionOpen : styles.filterSection}>
        <div className={styles.header}>
          <label>Filter by</label>
          <i onClick={e => props.toggleFilter(e, props.open)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M22 2L12 12L2 2" stroke="#FFEA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2 22L12 12L22 22" stroke="#FFEA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </i>
        </div>

        <div>
          <div className={styles.filterNames}>
            <label onClick={e => selectFilter(e, 'vibes')} className={currentTab === 'vibes' ? styles.selectedTab : ''}>Vibes</label>
            <label onClick={e => selectFilter(e, 'duration')} className={currentTab === 'duration' ? styles.selectedTab : ''}>Duration</label>
          </div>
          <div className={styles.filterValues}>
            {currentTab === 'vibes' && 
              <div>
                {props.categories.map((category, i) => {
                  return(<label onClick={e => onCategoryClick(e, category)}><i>{selectedCategories.includes(category['_id']) ? checkboxSelectedIcon() : checkboxIcon()}</i> {category.label}</label>)  
                })}
              </div>
            }

            {currentTab === 'duration' && 
              <div>
                {props.durations.map((duration, i) => {
                  return(<label onClick={e => onDurationClick(e, duration)}><i>{selectedDurations.includes(duration) ? checkboxSelectedIcon() : checkboxIcon()}</i> {duration}</label>)  
                })}
              </div>
            }

          </div>

          <div className={styles.btnsWrap}>
            <button className={styles.clearBtn} onClick={e => clearFilters(e)}>Clear all</button>
            <button className={styles.applyBtn} onClick={e => applyFilters(e)}>Apply</button>
          </div>

        </div>

      </div>
    </div>
  );
}  
export default Filters;
