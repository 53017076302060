const MetaHelper = {};

MetaHelper.getTitleAndDesc = (pathname='') => {
  let title = '';
  let description = '';
  let data = '';
  const subdomain = location.host.split('.')[0];

  switch (pathname) {
      case "/":
        title = subdomain === 'aumalterproject' ? 'Aum Alter Project' : "XPHive Home";
        description = subdomain === 'aumalterproject' ? 'AUM Alter Project curates wellness focused experiences across multiple locations in Himachal and Goa.' : "Travel vibe is how we recognize the aura you radiate, and then assist you in finding a group of like-minded individuals to embark on journeys together.";
        break;
      case (pathname.match('experiences/') || {}).input:
        data = pathname.split('/experiences/')[1].split('/');
        title = 'Experience Details' + ' (' + data[0].split('-').join(' ') + ')' + (data[1] ? ' | ' + data[1] : '');
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('communities/') || {}).input:
        data = pathname.split('/communities/')[1].split('/');
        title = 'Community Details' + ' (' + data[0].split('-').join(' ') + ')';
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('groups/') || {}).input:
        data = pathname.split('/groups/')[1].split('/');
        title = 'Group Details' + ' (' + data[0].split('-').join(' ') + ')';
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('destinations/') || {}).input:
        title = "XPHive Official - Destination Guide";
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('listings') || {}).input:
        title = "XPHive Official - Listings";
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('creators/') || {}).input:
        title = "XPHive Official - Creator Details";
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('brands/') || {}).input:
        const brandData = pathname.split('/brands/')[1].split('/');
        title = 'Brands Details' + ' (' + brandData[0].split('-').join(' ') + ')' + (brandData[1] ? ' | ' + brandData[1] : '');
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('blogs/') || {}).input:
        data = pathname.split('/blogs/')[1].split('/');
        title = 'Blog Details' + ' (' + data[0].split('-').join(' ') + ')' + (data[1] ? ' | ' + data[1] : '');
        description = "Welcome to XPHive Official Website";
        break;
      case "/privacy-policy":
        title = "XPHive Official - Privacy Policy";
        description = "Welcome to XPHive Official Website";
        break;
      case "/privacy-policy":
        title = "XPHive Official - Privacy Policy";
        description = "Welcome to XPHive Official Website";
        break;
      case "/travel-with-xphive":
        title = "Come travel with XPhive";
        description = "Come travel with XPhive";
        break;
      case "/booking-success":
        title = "Booking Success | XPHive";
        description = "Your booking is confirmed";
        break;
      case "/refund-policy":
        title = "XPHive Official - Refund Policy";
        description = "Welcome to XPHive Official Website";
        break;
      case "/terms-policy":
        title = "XPHive Official - Terms Policy";
        description = "Welcome to XPHive Official Website";
        break;
      case "/about-us":
        title = "XPHive Official - About us";
        description = "Welcome to XPHive Official Website";
        break;
      case "/contact-us":
        title = "XPHive Official - Contact us";
        description = "Welcome to XPHive Official Website";
        break;
      case "/get-in-touch":
        title = "Get In Touch | XPHive";
        description = "Welcome to XPHive";
        break;
      case "/xpvibes":
        title = "Know your Travel Persona | Vibe Check | XPHive";
        description = "Vibe is the subtle energy based impression one projects, reflecting one’s emotional state, personality and the energy in radiates. When you travel, your vibe dictates the kind of places and experiences you choose to explore. Take this quick test to understand your travelling vibe and never ever have a bad travelling experience.";
        break;
      case "/myvibes":
        title = "My Travel Persona | Vibe Check | XPHive";
        description = "Vibe is the subtle energy based impression one projects, reflecting one’s emotional state, personality and the energy in radiates.";
        break;
      case "/summer-breeze":
        title = "Summer Breeze Carnival 2024 in Chail (Himachal Pradesh) | XPHive";
        description = "Join us for two days of celebrating music, art, and culture in the lap of the Himalayas.";
        break;
      case "/custom-wishlist":
        title = "XPHive - Create your custom wishlists";
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('wishlists/') || {}).input:
        title = "XPHive Official - Wishlist Details";
        description = "Welcome to XPHive Official Website";
        break;
      case (pathname.match('portfolio/') || {}).input:
        title = "XPHive Official - Portfolio";
        description = "Welcome to XPHive Official Website";
        break;
    }

  return { title, description };
};


export default MetaHelper;
