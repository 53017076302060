import styles from "./Share.module.css";
import React, { Component } from 'react';
import { RWebShare } from "react-web-share";
import { Circle, ShareIcon, ShareIconBlack, ShareIconBlackCircle, ShareIconWhite } from '../icons';
import { Helper } from '../components';


const Share = (props) => {

	const onClick = (e) => {
		e.stopPropagation();
		Helper.trackAnalytics(props.analyticsCategory || 'Share', 'Click_Share', props.url || window.location.href);
	}

  	return (
  		<div className={styles.shareContainer} style={props.style} onClick={e => onClick(e)}>
	      <RWebShare
	        data={{
	          text: props.tagLine || "Go Solo Stay Social!",
	          url: props.url || window.location.href,
	          title: "XP-Hive",
	        }}
	        onClick={() => console.log("shared successfully!")}
	      >
	      	{!props.btn ? <div>
				<i className={styles.shareIcon} style={props.iconStyle}>
					{props.blackIcon ? (props.hideCircle ? <ShareIconBlack width={props.iconWidth} height={props.iconHeight} /> : <ShareIconBlackCircle border={props.border} />) : (props.circle ? <ShareIconWhite /> : <ShareIcon />)}
				</i>
			</div> : <div className={styles.shareBtn}>
				<span>Share</span>
				<i className={styles.shareIcon}><ShareIcon /></i>
			</div>}
	      </RWebShare>
	    </div>
  	);  
}  
export default Share;
