import styles from "./FindYourHive.module.css";
import React, { useState, useEffect, useRef, } from 'react';  
import Helper from './Helper';
import parse from 'html-react-parser';
import { DownArrow, RightBigArrow, CloseBlack, BackArrowWhite, } from '../icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RWebShare } from "react-web-share";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const FindYourHive = (props) => {
  const myContainer = useRef(null);
  const [selectedVibes, setSelectedVibes] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const { height, width } = Helper.getWindowDimensions();
  const [currentStep, setCurrentStep] = useState(1);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [showAllInterests, setShowAllInterests] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('North Himalayan Realms');
  const [selectedDates, setSelectedDates] = useState([]);
  const [formState, setFormState] = useState({
    phone: '',
    name: '',
    email: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);

  setTimeout(
    () => {
      if (isTourOpen) setIsTourOpen(false)
    },
    10000
  );

  const onClickChoice = (e, que, type, val, selectionCap=0) => {
    formState[que] = formState[que] || [];

    if (type === 'choice-single') {
      if (formState[que][0] === val) {
        formState[que][0] = '';
        Helper.trackAnalytics(`FindYourHive`, 'Click_Remove_choice', val);
      } else {
        formState[que][0] = val;
        Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
      }
    } else {
      if (formState[que].includes(val)) {
        formState[que] = formState[que].filter(av => av !== val);
        Helper.trackAnalytics(`FindYourHive`, 'Click_Remove_choice', val);
      } else {
        if (selectionCap > 0) {
          if (formState[que].length < selectionCap) {
            formState[que].push(val);
            Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
          }
        } else {
          formState[que].push(val);
          Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
        }
      }
    }

    setFormState({...formState});
  };

  const popularInterests = [
    {
      name: 'Philosophy',
      icon: '/icons/philosopher.svg'
    },
    {
      name: 'Music',
      icon: '/icons/live-music.svg'
    },
    {
      name: 'Acting/Theatre',
      icon: '/icons/theatre.svg'
    },
    {
      name: 'House Parties',
      icon: '/icons/party.svg'
    },
    {
      name: 'Martial Arts',
      icon: '/icons/taekwondo.svg'
    },
    {
      name: 'Photography',
      icon: '/icons/camera.svg'
    },
    {
      name: 'Clubbing/Bar Hopping',
      icon: '/icons/night-club.svg'
    },
    {
      name: 'Adventure Sports',
      icon: '/icons/climbing-wall.svg'
    },
    {
      name: 'Cycling',
      icon: '/icons/cycling.svg'
    },
    {
      name: 'Brewed Beers',
      icon: '/icons/beer-cap.svg'
    },
    {
      name: 'Brewing Tea/Coffee',
      icon: '/icons/coffee-cup.svg'
    },
    {
      name: 'Street Food',
      icon: '/icons/food-cart.svg'
    },
    {
      name: 'Conversations',
      icon: '/icons/communication.svg'
    },
    {
      name: 'Food Ordering Apps',
      icon: '/icons/food-delivery.svg'
    },
    {
      name: 'Meditation',
      icon: '/icons/meditation-2.svg'
    },
    {
      name: 'Running',
      icon: '/icons/running-shoes.svg'
    },
    {
      name: 'Geo-Politics',
      icon: '/icons/geopolitical.svg'
    },
    {
      name: 'Board Games',
      icon: '/icons/board-game.svg'
    },
  ];

  const categoryWiseInterests = {
    'Creative Expressions': [
      {
        name: 'Crafting/DIY Projects',
        icon: '/icons/projects.svg'
      },
      {
        name: 'Writing',
        icon: '/icons/copy-writing.svg'
      },
      {
        name: 'Music',
        icon: '/icons/live-music.svg'
      },
      {
        name: 'Singing',
        icon: '/icons/microphone.svg'
      },
      {
        name: 'Acting/Theatre',
        icon: '/icons/theatre.svg'
      },
      {
        name: 'Photography',
        icon: '/icons/camera.svg'
      },
      {
        name: 'Video Content',
        icon: '/icons/gopro.svg'
      },
      {
        name: 'Painting/Sketching',
        icon: '/icons/paint-palette.svg'
      },
      {
        name: 'Digital Art',
        icon: '/icons/digital-art.svg'
      },
    ],
    'Social Vibes': [
      {
        name: 'House Parties',
        icon: '/icons/party.svg'
      },
      {
        name: 'Exploring Cafes',
        icon: '/icons/cafe.svg'
      },
      {
        name: 'Concerts/Live Events',
        icon: '/icons/concert.svg'
      },
      {
        name: 'Clubbing/Bar Hopping',
        icon: '/icons/night-club.svg'
      },
      {
        name: 'Art Exhibitions/Museums',
        icon: '/icons/exhibition.svg'
      },
      {
        name: 'Binging TV Shows',
        icon: '/icons/tv-show.svg'
      },
      {
        name: 'Gaming',
        icon: '/icons/console.svg'
      },
      {
        name: 'Board Games',
        icon: '/icons/board-game.svg'
      },
      {
        name: 'Conversations',
        icon: '/icons/communication.svg'
      },
      {
        name: 'Sports',
        icon: '/icons/sports.svg'
      },
      {
        name: 'Volunteering',
        icon: '/icons/volunteer.svg'
      },
      {
        name: 'Fashion & Style',
        icon: '/icons/bowtie.svg'
      },
    ],
    'Active Pursuits': [
      {
        name: 'Gymming',
        icon: '/icons/dumbbell.svg'
      },
      {
        name: 'Running',
        icon: '/icons/running-shoes.svg'
      },
      {
        name: 'Yoga',
        icon: '/icons/yoga.svg'
      },
      {
        name: 'Team Sports',
        icon: '/icons/shoot.svg'
      },
      {
        name: 'Solo Sports',
        icon: '/icons/tennis.svg'
      },
      {
        name: 'Martial Arts',
        icon: '/icons/taekwondo.svg'
      },
      {
        name: 'Dancing',
        icon: '/icons/dancing.svg'
      },
      {
        name: 'Adventure Sports',
        icon: '/icons/climbing-wall.svg'
      },
      {
        name: 'Swimming',
        icon: '/icons/swimming.svg'
      },
      {
        name: 'Cycling',
        icon: '/icons/cycling.svg'
      },
    ],
    'Mindful Living': [
      {
        name: 'Meditation',
        icon: '/icons/meditation-2.svg'
      },
      {
        name: 'Spiritual Practices',
        icon: '/icons/meditation-1.svg'
      },
      {
        name: 'Journaling',
        icon: '/icons/journal.svg'
      },
      {
        name: 'Gardening',
        icon: '/icons/gardening.svg'
      },
      {
        name: 'Digital Detox',
        icon: '/icons/mobile.svg'
      }
    ],
    'Culinary Explorations': [
      {
        name: 'Cooking/Baking',
        icon: '/icons/cooking.svg'
      },
      {
        name: 'Exploring New Cuisines',
        icon: '/icons/cuisine.svg'
      },
      {
        name: 'Street Food',
        icon: '/icons/food-cart.svg'
      },
      {
        name: 'Wine Tasting',
        icon: '/icons/wine-tasting.svg'
      },
      {
        name: 'Brewed Beers',
        icon: '/icons/beer-cap.svg'
      },
      {
        name: 'Fermentation Projects',
        icon: '/icons/fermentation.svg'
      },
      {
        name: 'Brewing Tea/Coffee',
        icon: '/icons/coffee-cup.svg'
      },
      {
        name: 'Food Ordering Apps',
        icon: '/icons/food-delivery.svg'
      }
    ],
    "Explorer's Mindset": [
      {
        name: 'Learning Languages',
        icon: '/icons/language.svg'
      },
      {
        name: 'Astronomy',
        icon: '/icons/astronomy.svg'
      },
      {
        name: 'Puzzles',
        icon: '/icons/puzzle.svg'
      },
      {
        name: 'History',
        icon: '/icons/hourglass.svg'
      },
      {
        name: 'Mythology',
        icon: '/icons/maze.svg'
      },
      {
        name: 'Philosophy',
        icon: '/icons/philosopher.svg'
      },
      {
        name: 'Science & Innovation',
        icon: '/icons/atom.svg'
      },
      {
        name: 'Books (Fiction)',
        icon: '/icons/book-2.svg'
      },
      {
        name: 'Books (Non-Fiction)',
        icon: '/icons/book-1.svg'
      },
      {
        name: 'AI & Future Tech',
        icon: '/icons/microchip.svg'
      },
      {
        name: 'Geo-Politics',
        icon: '/icons/geopolitical.svg'
      },
    ]
  };

  const questionnaires1 = [
    {
      "question": "What’s your preferred trip duration?",
      "type": "choice-single",
      "choices": [
        "1-2 days",
        "3-5 days",
        "6+ days",
        "Surprise me!"
      ]
    },
    {
      "question": "Pick your preferred trip start dates!",
      "type": "dates",
      "choices": []
    },
    {
      "question": "What’s your estimated budget for this trip?",
      "type": "choice-single",
      "choices": [
        "Under ₹10K",
        "₹10-₹20K",
        "₹20K-₹30K",
        "₹30K-₹40K",
        "₹40K-₹50K",
        "Happy to pay for what it’s worth!"
      ]
    },
    {
      "question": "Who do you vibe best with?",
      "type": "choice-single",
      "choices": [
        "Solo wanderers and free spirits",
        "Chill small groups (3-5 people)",
        "Larger squads (6+ people) for maximum fun!",
        "I’m cool with anything as long as we vibe!"
      ]
    },
    {
      "question": "Have a travel idea in mind? Share it, and we’ll see if we can make it happen!",
      "type": "textarea",
      "placeholder": "I’d love to join a group that hikes to remote temples.",
      "choices": []
    },
  ];

  const questionnaires2 = [
    {
      "question": "What’s your current hustle?",
      "type": "choice-single",
      "choices": [
        "Student (Still hitting the books!)",
        "Professional (I’ve got a 9 to 5 or more)",
        "Entrepreneur / Business Owner (I’m building my empire)",
        "Freelancer / Consultant (I work on my terms)",
        "Retired (Living the dream, no deadlines)",
        "Other (I’ve got a unique vibe, tell us more!)"
      ]
    },
    {
      "question": "What’s your birth year? (Let’s see where your timeline starts!)",
      "type": "text",
      "placeholder": "Birth year",
      "choices": []
    },
    {
      "question": "Where will you be starting your journey from?",
      "type": "text",
      "placeholder": "Location",
      "choices": []
    },
    {
      "question": "What kind of explorer are you? Share your travel mantra!",
      "type": "textarea",
      "placeholder": "I love exploring offbeat paths and meeting new people.",
      "choices": []
    }
  ];

  const onChangeInput = (e, type) => {
    e.preventDefault();

    if (type === 'phone') {
      formErrorState['phone'] = false;
      setFormErrorState({...formErrorState});
      if (e.target.value.length < 11) {
        formState['phone'] = e.target.value;
        setFormState({...formState});
      }
    } else if (type === 'name') {
      formErrorState.name = false;
      setFormErrorState({...formErrorState});
      const result = e.target.value.replace(/[^a-z ]/gi, '');
      formState.name = result;
      setFormState({...formState});
    } else if (type === 'email') {
      formErrorState.email = false;
      setFormErrorState({...formErrorState});
      formState.email = e.target.value;
      setFormState({...formState});
    } else {
      formErrorState[type] = false;
      setFormErrorState({...formErrorState});
      formState[type] = e.target.value;
      setFormState({...formState});
    }
  };

  const getFormattedDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthName = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return`${monthName} ${day}, ${year}`;
  };

  const handleDateChange = (date) => {
    // If the date is already selected, remove it; otherwise, add it
    const newDate = new Date(date.setHours(0, 0, 0, 0)); // Clear time part

    // Check if the date is already in the array
    const isDateSelected = selectedDates.some(d => d.getTime() === newDate.getTime());

    if (isDateSelected) {
      // Remove the date
      const updatedDates = selectedDates.filter(d => d.getTime() !== newDate.getTime());
      setSelectedDates(updatedDates);
    } else {
      if (selectedDates.length < 3) {
        setSelectedDates([...selectedDates, newDate]);
      } else {
        toast.error('Selection limit 3 - choose wisely! ', Helper.ToastOptions);
      }
    }
  };

  const removeDate = (dateToRemove) => {
    const updatedDates = selectedDates.filter(d => d !== dateToRemove);
    setSelectedDates(updatedDates);
  };

  const suggestedDates = [
    {
      title: 'Eid<br/>Weekend',
      date: new Date('Mar 28 2025 00:00:00'),
      label: 'From March 28'
    },
    {
      title: 'Weekend <br/>Getaway',
      date: new Date('Apr 4 2025 00:00:00'),
      label: 'From April 4'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('Apr 11 2025 00:00:00'),
      label: 'From April 11'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('Apr 18 2025 00:00:00'),
      label: 'From April 18'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('Apr 25 2025 00:00:00'),
      label: 'From April 25'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('May 2 2025 00:00:00'),
      label: 'From May 2'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('May 9 2025 00:00:00'),
      label: 'From May 9'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('May 16 2025 00:00:00'),
      label: 'From May 16'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('May 23 2025 00:00:00'),
      label: 'From May 23'
    },
    {
      title: ' Weekend <br/>Getaway',
      date: new Date('May 30 2025 00:00:00'),
      label: 'From May 30'
    }
  ];

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };


  const getQuestionnaires = (data) => {
    return data.map((inputQue, i) => {
      return(
        (inputQue.type === 'choice-single' || inputQue.type === 'choice-multi') ? 
          <div key={i} style={{marginBottom: '12px'}}>
            <label className={styles.label}>{inputQue.question}</label>
            {inputQue.choices.map((choice, j) => {
                return(<span key={j} onClick={e => onClickChoice(e, inputQue.question, inputQue.type, choice)} className={(formState[inputQue.question] || []).includes(choice) ? styles.choiceSelected : styles.choice}>{parse(choice)}</span>)
            })}
          </div>
        : (inputQue.type === 'textarea' ?
          <div key={i} style={{marginBottom: '12px'}}>
            <label className={styles.label}>{inputQue.question}</label>
            <textarea className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} placeholder={inputQue.placeholder} value={formState[inputQue.question]} rows="2" onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
          : (inputQue.type === 'dates' ? 
            (<div key={i} style={{textAlign: 'center', paddingBottom: '20px', paddingTop: '8px'}}>
              <label className={styles.label}>{inputQue.question}</label>

              <div className={styles.suggestedDates}>
                {suggestedDates.map((suggestedDate, i) => {
                  return(
                    <div className={selectedDates.some(d => d.getTime() === suggestedDate.date.getTime()) ? styles.suggestedDateSelected : styles.suggestedDate} onClick={e => handleDateChange(suggestedDate.date)}>
                      <h6>{parse(suggestedDate.title)}</h6>
                      <label>{suggestedDate.label}</label>
                    </div>
                  );
                })}
              </div>

              <div>
                <div className={styles.customDates} onClick={toggleDatePicker}>
                  <label>Choose Custom Trip Start Dates</label>
                  <img className={styles.arrow} src={showDatePicker ? '/aup-black.svg' : '/adown-black.svg'} />
                </div>
              
                {showDatePicker && <DatePicker
                  selected={null}
                  onChange={handleDateChange}
                  inline
                  highlightDates={selectedDates} // Highlight the selected dates
                  shouldCloseOnSelect={false} // Keep picker open after date selection
                  dayClassName={day =>
                    selectedDates.some(d => d.getTime() === day.getTime()) ? 'selected' : ''
                  } // Custom class for selected days
                  dateFormat="MMMM dd, yyyy" // Format the displayed date
                  minDate={new Date()} 
                />}
              </div>
              
              {selectedDates.length > 0 && (
                <div className={styles.tabs} style={{marginBottom: 0}}>
                  <h5>Chosen Trip Start Dates</h5>
                  {selectedDates.map((date, index) => (
                    <div key={index} onClick={() => removeDate(date)} className={styles.tabSelected}>{getFormattedDate(date)}<img className={styles.cancleCircle} src="/cancel-circle.svg" /></div>
                  ))}
                </div>
              )}

            </div>) 
            : (<div key={i} style={{marginBottom: '12px'}}>
              <label className={styles.label}>{inputQue.question}</label>
              <input className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} type={inputQue.type} placeholder={inputQue.placeholder} value={formState[inputQue.question]} onChange={e => onChangeInput(e, inputQue.question)} />
            </div>)
          )
        )
      )
    });
  };

  const closeTour = (e) => {
    e.stopPropagation();
    setIsTourOpen(false);
  };

  const removeEmptyArrays = (obj) => {
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key]) && (obj[key].length === 0 || (obj[key].length === 1 && obj[key][0] === ''))) {
            delete obj[key];
        }
    });
    return obj;
  };

  const moveToNextStep = (event) => {
    event.preventDefault();
    if (myContainer.current) {
      myContainer.current.scrollTo(0, 0);
    }
    if (currentStep === 1 && selectedVibes.length < 3) {
      toast.error('Please choose at least 3 Vibes', Helper.ToastOptions);
    } else if (currentStep === 2 && selectedInterests.length < 5) {
      toast.error('Please select at least 5 interests', Helper.ToastOptions);
    } else if (currentStep === 3 && selectedDestinations.length < 1) {
      toast.error('Please select at least 1 destination', Helper.ToastOptions);
    } else if (currentStep === 4 && selectedDates.length === 0) {
      toast.error('Please select at least 1 date', Helper.ToastOptions);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!formState.name || formState.phone.length != 10 || !formState.email) {
      toast.error('Please fill the details', Helper.ToastOptions);
      if (myContainer.current) {
        myContainer.current.scrollTo(0, 10000);
      }
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email) {
      Helper.trackAnalytics(`FindYourHive`, 'Click_Find_my_hive', formState.phone);
      setSubmittingForm(true);
      const details = {...formState, 'Selected Vibes': selectedVibes, 'Selected Destination': selectedDestinations, 'Selected Interests': selectedInterests, 'Selected Dates': selectedDates};
      delete details.name;
      delete details.email;
      delete details.phone;
      removeEmptyArrays(details);

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          details,
          type: 'findYourHive',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          if (data.status == 'success') {
            setCurrentStep(currentStep + 1); 
          }
        })
        .catch((err) => {
          setSubmittingForm(false);
          console.log(err.message);
        });
    }
  };

  const vibeImages = [
    {
      id: "Hiking is Therapy",
      src: '/fmh/1.png',
    },
    {
      id: "Soaking it all Slowly",
      src: '/fmh/2.png',
    },
    {
      id: "Express Through Art",
      src: '/fmh/3.png',
    },
    {
      id: "Story-telling Nights",
      src: '/fmh/4.png',
    },
    {
      id: "Bikes & Bros",
      src: '/fmh/5.png',
    },
    {
      id: "Connect with Inner Self",
      src: '/fmh/6.png',
    },
    {
      id: "Chasing the Thrill",
      src: '/fmh/7.png',
    },
    {
      id: "Explore Like a Local",
      src: '/fmh/8.png',
    },
    {
      id: "Where Map Dont Reach",
      src: '/fmh/9.png',
    },
    {
      id: "Flavors Lead the Way",
      src: '/fmh/10.png',
    },
    {
      id: "Travel, Wrapped in Comfort",
      src: '/fmh/11.png',
    },
    {
      id: "Sunsets & Sundowners",
      src: '/fmh/12.png',
    },
  ];

  const destinationsImages = {
    'North Himalayan Realms': [
      {
        id: 'Kullu Valley',
        src: '/places/kullu.jpg',
        desc: 'Picturesque villages, vibrant local culture, and adventure sports make it an exciting and welcoming destination.',
        active: true
      },
      {
        id: 'Tirthan Valley',
        src: '/places/tirthan.jpg',
        desc: 'Hidden gem for pristine nature, hiking trails, and tranquil riverside escapes, away from crowds.',
        active: true
      },
      {
        id: 'Parvati Valley',
        src: '/places/parvati.jpg',
        desc: 'Vibrant cafes, charming villages, and relaxing hot springs create a perfect blend of chill vibes and cultural exploration.'
      },
      {
        id: 'Bir-Billing',
        src: '/places/billing.jpg',
        desc: 'Known for world-class paragliding, peaceful monasteries, and scenic beauty, it’s ideal for adventurers seeking serenity.',
        active: true
      },
      {
        id: 'Spiti Valley',
        src: '/places/spiti.jpg',
        desc: 'Remote, barren beauty, ancient monasteries, and quiet villages provide a sense of solitude and connection to nature.',
        active: true
      },
      {
        id: 'Ladakh',
        src: '/places/ladakh.jpg',
        desc: 'Rugged mountains, thrilling roads, and spiritual monasteries offer unparalleled adventure and self-discovery amidst breathtaking scenery.'
      },
      {
        id: 'Kashmir Valley',
        src: '/places/kashmir.jpg',
        desc: 'Serene landscapes, mesmerizing lakes, and welcoming locals make it a peaceful haven for solo explorers seeking nature and tranquility.'
      },
      {
        id: 'Doon Valley',
        src: '/places/doon.jpg',
        desc: 'A sanctuary of spirituality, adventure, and nature, offering river rafting, serene retreats, and scenic trails.'
      },
      {
        id: 'Nanda Devi Frontier',
        src: '/places/nanda.jpg',
        desc: 'A Himalayan haven of snow-capped peaks, skiing, and ancient villages, where adventure meets serenity.'
      },
      {
        id: 'Kangra Valley',
        src: '/places/kv.jpg',
        desc: 'Tibetan culture, serene monasteries, and Triund’s iconic trek offer a peaceful yet enriching experience.'
      },
      {
        id: 'Shimla Hills',
        src: '/places/shimla.jpg',
        desc: 'A blend of colonial charm, lush trails, and mountain adventures, rich in history and breathtaking views.'
      }
    ],
    'Southern Bliss': [
      {
        id: 'Hampi',
        src: '/places/hampi.jpg',
        desc: 'Ancient ruins, fascinating history, and unique landscapes make it a captivating exploration of India’s past.'
      },
      {
        id: 'Kodaikanal',
        src: '/places/kodaikanal.jpg',
        desc: 'Scenic lakes, misty hills, and serene trails provide a cool and calm escape from city life.'
      },
      {
        id: 'Munnar',
        src: '/places/munnar.jpg',
        desc: 'Rolling tea plantations, cool weather, and peaceful trekking trails provide a serene getaway.'
      },
      {
        id: 'Mysore Wilderness Circuit',
        src: '/places/circuit.jpg',
        desc: 'Wildlife safaris, lush forests, and scenic hills create a perfect blend of adventure and tranquility.'
      },
      {
        id: 'Wayanad',
        src: '/places/wayanad.jpg',
        desc: 'Dense forests, waterfalls, and hidden caves make it a peaceful haven for nature seekers.'
      },
      {
        id: 'Ooty & Coonoor',
        src: '/places/conoor.jpg',
        desc: 'Stunning landscapes, colonial charm, and serene tea estates offer a refreshing hill station retreat.'
      },
      {
        id: 'Coorg (Kodagu)',
        src: '/places/coorg.jpg',
        desc: 'Coffee plantations, misty hills, and tranquil homestays create a rejuvenating escape amidst nature.'
      },
    ],
    'Deserts & Plains': [
      {
        id: 'Varanasi',
        src: '/places/varanasi.jpg',
        desc: 'Rich spiritual energy, ghats along the Ganges, and historical significance create a deeply transformative experience.'
      },
      {
        id: 'Jaipur',
        src: '/places/jaipur.jpg',
        desc: 'Palaces, bustling bazaars, and architectural splendor provide an iconic Rajasthani experience.'
      },
      {
        id: 'Udaipur',
        src: '/places/udaipur.jpg',
        desc: 'Romantic lakes, opulent palaces, and serene vibes create a regal and relaxing retreat.'
      },
      {
        id: 'Jodhpur',
        src: '/places/jodhpur.jpg',
        desc: 'Blue-washed streets, majestic forts, and rich heritage make it a vibrant cultural hub.'
      },
      {
        id: 'Jaisalmer',
        src: '/places/jaisalmer.jpg',
        desc: 'Golden desert landscapes, historic forts, and vibrant markets create an enchanting Rajasthani experience.'
      },
      {
        id: 'Pushkar',
        src: '/places/pushkar.jpg',
        desc: 'Sacred ghats, vibrant markets, and serene lake views make it a blend of spirituality and culture.'
      },
    ],
    'East Himalayan Realms': [
      {
        id: 'Sikkim',
        src: '/places/sikkim.jpg',
        desc: 'Majestic peaks, serene monasteries, and vibrant culture make it a perfect Himalayan escape.'
      },
      {
        id: 'Meghalaya',
        src: '/places/meghalaya.jpg',
        desc: 'Known for vibrant culture, lush landscapes, and crystal-clear rivers, it’s a refreshing nature retreat.'
      },
      {
        id: 'Arunachal Pradesh',
        src: '/places/arunachal.jpg',
        desc: 'Remote valleys, ancient monasteries, and breathtaking landscapes offer a serene and adventurous experience.'
      },
      {
        id: 'Kaziranga & Majuli',
        src: '/places/majuli.jpg',
        desc: 'Wildlife safaris, cultural traditions, and river island beauty create a captivating mix of adventure and heritage.'
      },
    ],
    'Beaches & Islands': [
      {
        id: 'North Goa',
        src: '/places/north-goa.jpg',
        desc: 'Lively beaches, vibrant nightlife, and water sports create an energetic coastal retreat.'
      },
      {
        id: 'South Goa',
        src: '/places/south-goa.jpg',
        desc: 'Serene beaches, quiet villages, and beautiful sunsets make it a peaceful seaside getaway.'
      },
      {
        id: 'Pondicherry',
        src: '/places/pondicherry.jpg',
        desc: 'French-inspired architecture, serene beaches, and vibrant cafes create a laid-back yet colorful retreat.'
      },
      {
        id: 'Gokarna',
        src: '/places/gokarna.jpg',
        desc: 'Quiet beaches, sacred temples, and relaxed ambiance make it a hidden gem on the Karnataka coast.'
      },
      {
        id: 'Varkala',
        src: '/places/varkala.jpg',
        desc: 'Cliffside beaches, yoga retreats, and a laid-back vibe make it a rejuvenating coastal escape.'
      },
      {
        id: 'Alleppey (Alappuzha)',
        src: '/places/allepey.jpg',
        desc: 'Backwater houseboats, lush landscapes, and tranquil canals offer a unique and peaceful experience.'
      },
      {
        id: 'Andaman Islands',
        src: '/places/andaman.jpg',
        desc: 'Pristine beaches, crystal-clear waters, and tropical beauty create an idyllic island escape.'
      },
      {
        id: 'Lakshadweep',
        src: '/places/lakshadweep.jpg',
        desc: 'Coral reefs, marine life, and secluded islands make it an exclusive and serene beach getaway.'
      },
    ]
  };

  const toggleDayToDayVive = (vibeId) => {
    let currentSelectedVibes = [];
    setSelectedVibes((prevSelected) => {
      if (prevSelected.includes(vibeId)) {
        currentSelectedVibes = prevSelected.filter((id) => id !== vibeId);
      } else {
        if (prevSelected.length < 6) {
          currentSelectedVibes = [...prevSelected, vibeId];
        } else {
          toast.error('Selection limit 6 - choose wisely! ', Helper.ToastOptions);
          currentSelectedVibes = [...prevSelected];
        }
      }

      return currentSelectedVibes;
    });
  };

  const changeRegion = (region) => {
    setSelectedRegion(region);
  };

  const toggleDestinations = (vibeId) => {
    let currentSelectedDestinations = [];
    setSelectedDestinations((prevSelected) => {
      if (prevSelected.includes(vibeId)) {
        currentSelectedDestinations = prevSelected.filter((id) => id !== vibeId);
      } else {
        if (prevSelected.length < 3) {
          currentSelectedDestinations = [...prevSelected, vibeId];
        } else {
          toast.error('Selection limit 3 - choose wisely! ', Helper.ToastOptions);
          currentSelectedDestinations = [...prevSelected];
        }
      }

      return currentSelectedDestinations;
    });
  };

  const toggleInterests = (interest) => {
    let currentSelectedInterests = [];
    setSelectedInterests((prevSelected) => {
      if (prevSelected.includes(interest)) {
        currentSelectedInterests = prevSelected.filter((preInt) => preInt !== interest);
      } else {
        if (prevSelected.length < 10) {
          currentSelectedInterests = [...prevSelected, interest];
        } else {
          toast.error('Selection limit 10 - choose wisely! ', Helper.ToastOptions);
          currentSelectedInterests = [...prevSelected];
        }
      }

      return currentSelectedInterests;
    });
  };

  const chunkArray = (arr) => {
    arr = arr.filter(des => des.active);
    return Array.from({ length: Math.ceil(arr.length / 2) }, (_, i) =>
      arr.slice(i * 2, i * 2 + 2)
    );
  }

  return (
    <div style={{overflow: 'hidden', textAlign: 'center'}}>

      <div ref={myContainer} className={styles.container} style={{bottom: (props.open ? '0' : '-6000px'), transition: (props.open ? 'all 0.3s ease' : 'all 1.5s ease')}}>
        {(currentStep > 1 && currentStep < 6) && <i onClick={(e) => setCurrentStep(currentStep - 1)} className={styles.backArrow}><BackArrowWhite /></i>}
        <i style={{right: (currentStep > 1 && currentStep < 6) ? '8px': '0', left: (currentStep > 1 && currentStep < 6) ? 'auto': '0'}} className={styles.downArrow} onClick={props.toggleFindYourHive}><DownArrow fill={'#fff'} width={'40'} height={'40'} /></i>
        

        {currentStep === 1 && <div>
          <h3>Pick your Travel Vibe</h3>
          <p>Your vibe reflects how you love to travel—whether it’s chasing sunsets, exploring hidden trails, or bonding over bonfires.<br/>Choose <b>at least 3 Vibes</b> that speak to your travel soul and we’ll craft the perfect journey for you!</p>

          <div className={styles.imageGrid}>
            {vibeImages.map((image, i) => (
              <div key={image.id} style={{width: ((width - 36) / 2) - 5, height: ((width - 36) / 2) - 5}} className={`${selectedVibes.includes(image.id) ? styles.imageTileSelected : styles.imageTile}`} onClick={() => toggleDayToDayVive(image.id)}>
                
                {i === 1 && isTourOpen && <div className={styles.tourWrap}>
                  <i onClick={(e) => closeTour(e)} className={styles.closeIcon}><CloseBlack /></i>
                  <label>Click on the image to select</label>
                  <div className={styles.leftArrow}></div>
                </div>}

                <img src={image.src} alt={`Image ${image.id}`} />
                {selectedVibes.includes(image.id) && <img className={styles.checkIcon} src="/check-icon-yellow2.png" />}
              </div>
            ))}
          </div>
          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next_from_vibes', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 2 && <div className={styles.interestsContainer}>
          <h3>Outside Travel, What’s Your Vibe?</h3>
          <p>Select at least 5 hobbies or interests that you enjoy spending time on. This helps us understand you better and connect you with like-minded travelers.</p>

          <div className={styles.popularInterests}>
            <h3>Popular Vibes</h3>

            <div className={styles.tabs}>
              {popularInterests.map((pi, i) => {
                return(
                  <div onClick={() => toggleInterests(pi.name)} kay={i} className={`${selectedInterests.includes(pi.name) ? styles.tabSelected : styles.tab}`}><img src={pi.icon} />{pi.name}</div>
                )
              })}
            </div>
          </div>

          <div className={styles.showAllLess}>
            {showAllInterests && <div onClick={(e) => setShowAllInterests(false)}><img src="/aup.svg" />Show Less</div>}
            {!showAllInterests && <div onClick={(e) => setShowAllInterests(true)}><img src="/adown.svg" />Show All</div>}
          </div>


          {showAllInterests && Object.keys(categoryWiseInterests).map(category => (
            <div className={styles.interests}>
              <h3>{category}</h3>

              <div className={styles.tabs}>
                {categoryWiseInterests[category].map((pi, i) => {
                  return(
                    <div onClick={() => toggleInterests(pi.name)} kay={i} className={`${selectedInterests.includes(pi.name) ? styles.tabSelected : styles.tab}`}><img src={pi.icon} />{pi.name}</div>
                  )
                })}
              </div>
            </div>
          ))}


          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next_from_outside_travel_vibe', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 3 && <div style={{paddingBottom: '70px'}}>
          <h3>Where Does Your Heart Belong?</h3>
          <p>Pick at least one destination to get started! Vibe matching is active here — more coming soon!</p>

          <div className={styles.tabs} style={{display: 'none'}}>
            <div onClick={() => changeRegion('North Himalayan Realms')} className={`${selectedRegion === 'North Himalayan Realms' ? styles.tabSelected : styles.tab}`}><img src="/icons/himalaya.svg" />North Himalayan Realms</div>
            <div onClick={() => changeRegion('Southern Bliss')} className={`${selectedRegion === 'Southern Bliss' ? styles.tabSelected : styles.tab}`}><img src="/icons/tree.svg" />Southern Bliss</div>
            <div onClick={() => changeRegion('Deserts & Plains')} className={`${selectedRegion === 'Deserts & Plains' ? styles.tabSelected : styles.tab}`}><img src="/icons/desert.svg" />Deserts & Plains</div>
            <div onClick={() => changeRegion('East Himalayan Realms')} className={`${selectedRegion === 'East Himalayan Realms' ? styles.tabSelected : styles.tab}`}><img src="/icons/himalaya2.svg" />East Himalayan Realms</div>
            <div onClick={() => changeRegion('Beaches & Islands')} className={`${selectedRegion === 'Beaches & Islands' ? styles.tabSelected : styles.tab}`}><img src="/icons/vacations.svg" />Beaches & Islands</div>
          </div>
          
          {chunkArray(destinationsImages[selectedRegion]).map((arr, j) => {
            return(
              <div key={j} className={styles.desImageGrid}>
                {arr.map((destination, i) => {
                  return(
                    <div key={destination.id} style={{width: ((width - 36) / 2) - 5}} className={`${selectedDestinations.includes(destination.id) ? styles.imageTileSelected : styles.imageTile}`} onClick={() => toggleDestinations(destination.id)}>
                      <img src={destination.src} alt={`Image ${destination.id}`} />
                      {selectedDestinations.includes(destination.id) && <img style={{top: (((width - 36) / 2) - 5) / 2 }} className={styles.checkIcon} src="/check-icon-yellow2.png" />}
                    
                      <label className={styles.destinationName}>{destination.id}</label>
                      <span className={styles.destinationDesc}>{destination.desc}</span>
                    </div>
                  )
                })}
              </div>
            );
          })}

          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next_from_destinations', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 4 && <div className={styles.formContainer}>
          <h3>Plan It Your Way!</h3>
          <p>Set your trip details—and we’ll connect you with travelers on the same journey!</p>

          <div>
            <form style={{marginTop: '30px', paddingBottom: '60px'}}>
              {getQuestionnaires(questionnaires1)}
            </form>
          </div>

          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next_from_dates', 'Submit'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 5 && <div className={styles.formContainer}>
          <h3>You’re Almost There!</h3>
          <p>You’ve completed the hard part. Let’s wrap it up with some final details!</p>

          <div>
            <form style={{marginTop: '30px', paddingBottom: '60px'}}>
              {getQuestionnaires(questionnaires2)}

              <div style={{marginBottom: '12px'}}>
                <label className={styles.label}>Your name... *</label>
                <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
              </div>

              <div style={{marginBottom: '12px'}}>
                <label className={styles.label}>WhatsApp number... *</label>
                <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
              </div>

              <div style={{marginBottom: '12px'}}>
                <label className={styles.label}>Email ID... *</label>
                <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
              </div>
            </form>
          </div>

          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Submit', 'Submit'); submitForm(e); }}>Submit</button>}
        </div>}

        {currentStep === 6 && <div className={styles.formFilled}>
          <h3 style={{fontSize: '24px'}}>Vibe check complete! 🌍</h3>
          <span className={styles.formFilledPara}>We're matching you with like-minded co-travelers and groups—expect a WhatsApp message soon.</span>
          <div className={styles.shareAndHelp}>
            Got a friend who loves to explore? Spread the word!
            <RWebShare
              data={{
                text: "Looking for co-travelers who vibe right?",
                url: 'https://www.xphive.com',
                title: "XP-Hive",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button className={styles.shareBtn}><img src="/share-yellow.svg" />Share with Friends!</button>
            </RWebShare>
          </div>
          <div className={styles.whatsappWrap}>
            Need urgent help with your solo trip?
            <a target="_blank" href={`https://wa.me/+919972136020/?text=Hi`}><img src="/wa-v1.png" />Reach out to us!</a>
          </div>
        </div>}
        <ToastContainer />
      </div>
    </div>
  )
}

export default FindYourHive;
